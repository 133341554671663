import cn from 'classnames'

const overlay = (isOpen: boolean) =>
  cn(
    'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ',
    { 'opacity-0 ease-out duration-300': !isOpen },
    { 'opacity-100 ease-in duration-200': isOpen }
  )

const modal = (isOpen: boolean) =>
  cn(
    'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6',
    { 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !isOpen },
    { 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100': isOpen }
  )

const container = (isOpen: boolean) => cn('fixed inset-0 overflow-y-auto', { 'z-50': isOpen }, { '-z-1': !isOpen })

const closeButton =
  'bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'

export const styles = {
  overlay,
  modal,
  container,
  closeButton
}
