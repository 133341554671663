import React from 'react'
import { ascendingIcon, descendingIcon } from '../../common/icons'
import cn from 'classnames'
import { styles } from './styles'

interface Shape {
  label: string
  key: string
  isSortable?: boolean
}

interface TableProps {
  shape: Shape[]
  className?: string
  sortCallback?: (key: string) => void
  activeSort?: string
  sortDirection?: 'asc' | 'desc'
}
type TableImpl = React.FC<TableProps> & { Row: React.FC<TableRowProps>; Cell: React.FC<TableCellProps> }

interface TableRowProps {
  className?: string
}

interface TableCellProps {
  className?: string
}

interface TableHeadingProps {
  icon?: 'asc' | 'desc' | null
  className?: string
  onClick?: () => void
}

const TableHeading: React.FC<TableHeadingProps> = ({ children, icon, className, onClick, ...rest }) => {
  return (
    <th scope="col" className={cn(styles.heading, className)} onClick={onClick} {...rest}>
      <div className="flex">
        {children}
        {icon ? <span className="ml-1 w-4 h-4"> {icon === 'asc' ? ascendingIcon : descendingIcon}</span> : null}
      </div>
    </th>
  )
}

export const Table: TableImpl = (props) => {
  const { shape, children, sortCallback, activeSort, sortDirection } = props

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="pb-2 align-middle inline-block min-w-full">
          <div className="overflow-hidden border-b border-t border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {shape.map((item) => {
                    const icon = item.isSortable && item.key === activeSort ? sortDirection : null

                    return (
                      <TableHeading
                        key={item.key}
                        className={cn({ 'cursor-pointer': item.isSortable })}
                        onClick={() => item.isSortable && sortCallback && sortCallback(item.key)}
                        icon={icon}
                      >
                        {item.label}
                      </TableHeading>
                    )
                  })}
                </tr>
              </thead>
              <tbody>{children}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const Row: React.FC<TableRowProps> = (props) => {
  const { className, children } = props

  return <tr className={cn('bg-white', className)}>{children}</tr>
}

const Cell: React.FC<TableCellProps> = (props) => {
  const { className, children } = props

  return <td className={cn(styles.cell, className)}>{children}</td>
}

Table.Row = Row
Table.Cell = Cell
