import React, { ForwardedRef, forwardRef } from 'react'
import cn from 'classnames'
import { styles } from './styles'
import { Width } from './types'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  className?: string
  as: 'input' | 'textarea'
  variant: 'standard' | 'outline' | 'ghost'
  width: keyof Width
}

export const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((props, ref) => {
  const { className: overrideClassname, as = 'input', variant = 'standard', width = 'md', ...rest } = props
  const className = cn(styles.base, styles[variant], overrideClassname, styles.widths[width])
  const _props = { className, style: { boxShadow: 'none' }, ...rest }

  return as === 'input' ? (
    <input ref={ref as ForwardedRef<HTMLInputElement>} {..._props} />
  ) : (
    <textarea ref={ref as ForwardedRef<HTMLTextAreaElement>} {..._props} />
  )
})
