import React from 'react'
import { styles } from './styles'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { isOpen, onClose, children } = props

  const overlayStyle = styles.overlay(isOpen)
  const modalStyle = styles.modal(isOpen)
  const containerStyle = styles.container(isOpen)

  return (
    <div className={containerStyle} aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className={overlayStyle} aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>
        <div className={modalStyle}>
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button type="button" className={styles.closeButton} onClick={() => onClose()}>
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
