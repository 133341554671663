import React, { forwardRef } from 'react'
import cn from 'classnames'
import { styles } from './styles'
import { GapType } from './types'

interface GridProps {
  className?: string
  children?: React.ReactNode
  columns?: number
  rows?: number
  gap?: number
  columnGap?: number
  rowGap?: number
}

export const Grid = forwardRef<HTMLDivElement, GridProps>((props, ref) => {
  const { className: overrideClass, columns, rows, children, gap, columnGap, rowGap, ...rest } = props

  const className = cn(
    'grid',
    styles.getGridDimensions('column', columns),
    styles.getGridDimensions('row', rows),
    ...([
      { gap: gap, type: 'all' },
      { gap: columnGap, type: 'column' },
      { gap: rowGap, type: 'row' }
    ] as Array<{ gap: number; type: GapType }>).map(styles.getGap),
    overrideClass
  )

  return (
    <div ref={ref} className={className} {...rest}>
      {children}
    </div>
  )
})
