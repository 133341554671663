// @ts-ignore
import colors from 'tailwindcss/colors'

interface Obj {
  [x: string]: any
  [y: number]: any
}

export function get(obj: Obj, path: string | number | string[], fallback?: any) {
  let key
  switch (typeof path) {
    case 'string':
      key = path.split('.')
      break
    case 'number':
      key = [path]
      break
    case 'object':
      key = path
      break
    default:
      key = path
  }

  for (let index = 0; index < key.length; index += 1) {
    if (!obj) break
    obj = obj[key[index]]
  }

  return obj === undefined ? fallback : obj
}

export function isNumber(val: any) {
  return typeof val === 'number'
}

/* Returns the tailwind equivalent classname of a color */
export function getColor(color: string, intensity: number): string {
  if (Object.keys(colors).includes(color) && typeof colors[color] === 'object') {
    return `${color}-${intensity}`
  }

  return color
}
