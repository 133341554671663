import React, { forwardRef } from 'react'
import cn from 'classnames'
import { styles } from './styles'
import { ButtonSize, ButtonVariant } from './types'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  className?: string
  variant: ButtonVariant
  isDisabled?: boolean
  colorScheme?: string
  size?: ButtonSize
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, className, variant = 'primary', colorScheme = 'black', isDisabled, size = 'md', ...rest } = props

  const mergedClassName = cn(styles.default, styles.variants[variant](colorScheme), styles.sizes[size], className, {
    [styles.disabled]: isDisabled
  })

  return (
    <button ref={ref} className={mergedClassName} disabled={isDisabled} {...rest}>
      {children}
    </button>
  )
})
