import React, { forwardRef } from 'react'
import cn from 'classnames'
import { styles } from './styles'

interface NavItemProps {
  children?: React.ReactNode
  isActive?: boolean
  className?: string
}

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>((props, ref) => {
  const { children, isActive, className: overrideClassName } = props
  const className = cn(styles.navItemStandard, { [styles.navItemActive]: isActive }, overrideClassName)

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  )
})
