import React from 'react'
import cn from 'classnames'

interface LoaderProps {
	className?: string
	isActive?: boolean
}

const baseStyle = 'w-10 h-10 rounded-full'

export const Loader: React.FC<LoaderProps> = (props) => {
	const { className, isActive = true } = props
	return (
		<div
			className={cn(baseStyle, 'relative border-4 border-gray-200', { 'animate-spin': isActive }, className)}
			style={{ borderLeftColor: 'white' }}
		>
			<div className={baseStyle} />
		</div>
	)
}
